<template>
	<div id="activeSet" class="el-content">
		<com-power-form :form-data="formData"></com-power-form>
	</div>
</template>

<script>
import comPowerForm from '@/components/form/com-power-form.vue'
import { reactive, toRefs } from 'vue';
import userModel from '@/api/user';
export default{
	components:{
		comPowerForm
	},
	setup(){
		const state = reactive({
			formData:[
				{label:'活动发布自动审核',type:'radio',value:'',key:'shop_event_not_audit',dataType:'string',options:[
					{label:'自动审核',value:'1'},
					{label:'不自动审核',value:'0'},
				]},
				{label:'推广活动费用',type:'number',value:'',key:'activity_rate',dataType:'string',addon:'%'},
				{label:'活动手续费',type:'number',value:'',key:'activity_fee',dataType:'string',addon:'%'},
				{label:'检票员',type:'select-mutil',value:'',key:'event_sign',dataType:'json',options:[]}
			]
		})

		userModel.getServiceUserList(1,999).then(res=>{
			let user = []
			res.list.forEach(item=>user.push({label:item.im_user.nick_name,value:''+item.admin_id}))
			state.formData[3].options = user
		})

		return{
			...toRefs(state)
		}
	}
}
</script>

<style>
</style>
